const formValidation = () => {
  const form = document.querySelector<HTMLFormElement>('form');
  const formItems = document.querySelectorAll('.contact__item');
  const confirmBtn = document.querySelector<HTMLInputElement>('.js-button-confirm');

  if (!form || !confirmBtn) {
    console.error('Form or confirm button not found.');
    return;
  }

  const createError = (inputItem: any, errorMsg: any) => {
    let error = inputItem.querySelector('span.error');
    if (!error) {
      error = document.createElement('span');
      error.classList.add('error');
      error.appendChild(document.createTextNode(errorMsg));
      inputItem.appendChild(error);
    } else {
      error.textContent = errorMsg;
    }
  };

  const removeError = (inputItem: any) => {
    const error = inputItem.querySelector('span.error');
    if (error) {
      error.remove();
    }
  };

  const validateEmail = (input: any): boolean => {
    const regex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])([a-zA-Z0-9._-]*[a-zA-Z0-9])?@([a-zA-Z0-9-]+(\.[a-zA-Z]{2,4})+)$/i;
    return regex.test(input.value);
  };

  const checkEmpty = (): boolean => {
    let hasErrors = false;
    formItems.forEach(item => {
      const required = item.querySelector('.contact__label-text--req');
      const inputItem = item.querySelector('.contact__wrap');
      if (!required || !inputItem) return;
  
      const inputs = inputItem.querySelectorAll<HTMLInputElement>('input, textarea');
      inputs.forEach(input => {
        if (input.value === '') {
          createError(inputItem, '未入力です。');
          hasErrors = true;
        } else {
          if (input.getAttribute('type') === 'email') {
            if (!validateEmail(input)) {
              createError(inputItem, 'メールアドレスが正しくありません。');
              hasErrors = true;
            } else {
              removeError(inputItem);
            }
          } else {
            removeError(inputItem);
          }
        }
      });
    });
    return hasErrors;
  };  

  const checkErrors = (): boolean => {
    const firstError = document.querySelector('.error');
    if (firstError) {
      const errPos = firstError.getBoundingClientRect().top + window.scrollY + (-300);
      window.scrollTo({ top: errPos, behavior: 'smooth' });
      return true;
    }
    return false;
  };

  confirmBtn.addEventListener('click', (e) => {
    console.log('Confirm button clicked.');
    checkEmpty(); // Run validation
    if (!checkErrors()) {
      console.log('Validation passed. Submitting the form...');
      // Optional: You can manually submit the form if needed.
      // document.querySelector('form').submit(); // Uncomment this if necessary
    } else {
      e.preventDefault();
      console.log('Validation failed. Not submitting the form.');
    }
  });
};

export default formValidation;
