/**
 * Bar Animation
 */
const bar = () => {
  const chartContainer = document.getElementById('chart-container');
  const counter = document.querySelectorAll('.lp__chart-counter-num');
  const barList = document.querySelector('.lp__chart-bar-list');

  // @ts-ignore
  function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // @ts-ignore
  function animateCounter(target, duration, element) {
      let start = 0;
      const increment = target / duration;
      const timer = setInterval(() => {
          start += increment;
          if (start >= target) {
              clearInterval(timer);
              start = target;
          }
          element.textContent = numberWithCommas(Math.round(start));
      }, 1000 / 240);
  }

  //   @ts-ignore
  function displayView(entries, observer) {
    //   @ts-ignore
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        barList?.classList.add('animate');
        counter.forEach((numberElement) => {
            const target = parseInt(numberElement.getAttribute('data-target') || '0');
            animateCounter(target, 100, numberElement);
        });

        observer.unobserve(entry.target);
      }
    });
  }

  const observer = new IntersectionObserver(displayView, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  });

  //@ts-ignore
  observer.observe(chartContainer);
};

export default bar;