/**
 * Toggle Class
 */
const toggle = () => {

  const faqItems = document.querySelectorAll('.js-faq-accordion');
  if (faqItems) {
    faqItems.forEach(faqItem => {
      faqItem.addEventListener('click', () => {
        const faqQuestion = faqItem.querySelector('.lp__faq-question');
        const faqAnswer = faqItem.querySelector('.lp__faq-answer');

        faqQuestion?.classList.toggle('lp__faq-question--active');
        faqAnswer?.classList.toggle('lp__faq-answer--active');
      });
    });
  }

  const tenantTables = document.querySelectorAll('.js-tenant-table');
  if (tenantTables) {
    tenantTables.forEach(tenantTable => {
      tenantTable.addEventListener('click', function () {
        // @ts-ignore
        const tableContent = this.nextElementSibling;
        // @ts-ignore
        const tableTitleWrap = this.querySelector('.lp__tenant-tbl-title');

        if (tableContent?.classList.contains('lp__tenant-tbl-content')) {
          tableContent.classList.toggle('lp__tenant-tbl-content--active');
          tableTitleWrap?.classList.toggle('lp__tenant-tbl-title--active');
          tenantTable?.classList.toggle('lp__tenant-tbl-title-wrap--active');
        }
      });
    });
  }

  const rateExpand = document.querySelectorAll('.js-rate-expand');

  if (rateExpand) {
    rateExpand.forEach(item => {
      item.addEventListener('click', function () {
        // @ts-ignore
        const parentWrap = this.closest('.lp__rate-plan-wrap');
        const expandWrap = parentWrap.querySelector('.lp__rate-plan-expand');
        const expandWrapText = parentWrap.querySelector('.lp__rate-plan-btn-text');
    
        expandWrap?.classList.toggle('lp__rate-plan-expand--active');
        item.classList.toggle('lp__rate-plan-btn-expand--active');
        expandWrapText.innerHTML = '閉じる';
      });
    });
  }
  
};

export default toggle;
