/**
 * Slider functionality
 */
const splide = () => {

  const useSplider = document.querySelector('.js-use-slider');
  if (useSplider) {
    // @ts-ignore
    const useSplide = new Splide(useSplider, {
      mediaQuery: 'min',
      perPage: 3,
      pagination: false,
      focus: 'center',
      trimSpace: false,
      fixedWidth: '230px',
      fixedHeight: 'auto',
      gap: 40,
      breakpoints: {
        768: {
          destroy: true,
        }
      }
    });
    useSplide.mount();
  }

  const caseSlider = document.querySelector('.js-case-slide');
  if (caseSlider) {
    // @ts-ignore
    var caseSlide = new Splide( caseSlider, {
      type   : 'loop',
      perPage: 3,
      rewind : true,
      gap    : 40,
      breakpoints: {
        1200: {
          gap    : 30,
        },
        980: {
          perPage: 2,
        },
        767: {
          perPage: 1,
          gap    : 16,
          padding: '3.2rem',
        },
      }
    });
    caseSlide.mount();
  }

  const tenantSlider = document.querySelector('.js-tenant-slide');
  if (tenantSlider) {
    // @ts-ignore
    var tenantSlide = new Splide( tenantSlider, {
      type   : 'slide',
      perPage: 2,
      gap    : 24,
      pagination: false,
      padding: '4.2rem',
      breakpoints: {
        520: {
          perPage: 1,
        },
      }
    });
    tenantSlide.mount();
  }

};

export default splide;